/**
 * 商户类型
 */
const ModuleType = {
    Distribution: 1, //分销
    GroupBuy: 2, //团购
    CommunityGroupBuy: 3, //社区团购
    LiveBroadcast: 4, //直播
};

export default ModuleType;