/**
 * 首页组件类型
 */
class FirstPageType {

    /**
     * 默认为空
     */
    public Empty: any = {
        name: 'empty',
        type: 0
    };

    /**
     * banner1
     */
    public Banner1List: any = {
        name: 'first-page-banner1-list',
        type: 1
    };

    /**
     * 商品1
     */
    public Goods1List: any = {
        name: 'first-page-goods1-list',
        type: 2
    };

    /**
     * 活动/广告1
     */
    public Advertise1: any = {
        name: 'first-page-advertise1',
        type: 2
    };

    /**
     * 海报1
     */
    public Cover1: any = {
        name: 'first-page-cover1',
        type: 3,
        coverType: {
            product: 1, //商品
            advertise: 2, //活动/广告
        }
    };

    /**
     * 分类1
     */
    public Category1List: any = {
        name: 'first-page-category1-list',
        type: 4
    };

    /**
     * 名片
     */
    public visitingCard: any = {
        name: 'first-page-visiting-card',
        type: 6
    }

}

const c = new FirstPageType();
export {c as FirstPageType}