export default (Vue: any) => {
    Vue.directive('fen2yuan', (el: any, binding: any) => {
        let value = binding.value;
        el.innerHTML = (value / 100).toFixed(2)
    });
    Vue.directive('yuan2fen', (el: any, binding: any) => {
        let value = binding.value;
        el.innerHTML = value * 100
    });
    Vue.directive('dateFormat', (el: any, binding: any) => {
        let value = binding.value;
        let date = new Date(value.time);
        el.innerHTML = date.Format(value.fmt)
    })
}
