/**
 * 店铺工具
 */

const module: string = 'store';

export default [
    {
        name: `${module}_tool_wxMess`,
        component: import('@/views/store/ToolWxMess.vue')
    },
    {
        name: `${module}_tool_message`,
        component: import('@/views/store/ToolMessage.vue')
    },
    {
        name: `${module}_tool_resource`,
        component: import('@/views/store/ToolResource.vue')
    }
]