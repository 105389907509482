/**
 * 商品管理
 */

const module: string = 'goods';

export default [
    {
        name: `${module}_goods`,
        component: import('@/views/goods/Goods.vue')
    },
    {
        name: `${module}_category`,
        component: import('@/views/goods/Category.vue')
    },
    {
        name: `${module}_add_goods`,
        component: import('@/views/goods/AddGoods.vue')
    }
]