/**
 * 店铺管理
 */

const module: string = 'merchant';

export default [
    {
        name: `${module}_store_info`,
        component: import('@/views/merchant/StoreInfo.vue')
    },
    {
        name: `${module}_store_employee`,
        component: import('@/views/merchant/StoreEmployee.vue')
    },
    {
        name: `${module}_store_account`,
        component: import('@/views/merchant/StoreAccount.vue')
    },
    {
        name: `${module}_infoControl`,
        component: import('@/views/merchant/InfoControl.vue')
    }
]