
import {FirstPageType} from "@/common/FirstPageType";

export default [
    {
        name: FirstPageType.Empty.name,
        component: import('@/components/first-page-components/Empty.vue')
    },
    {
        name: FirstPageType.Banner1List.name,
        component: import('@/components/first-page-components/Banner1List.vue')
    },
    {
        name: FirstPageType.Goods1List.name,
        component: import('@/components/first-page-components/Goods1List.vue')
    },
    {
        name: FirstPageType.Category1List.name,
        component: import('@/components/first-page-components/Category1List.vue')
    },
    {
        name: FirstPageType.Advertise1.name,
        component: import('@/components/first-page-components/Advertise1List.vue')
    },
    {
        name: FirstPageType.visitingCard.name,
        component: import('@/components/first-page-components/VisitingCardList.vue')
    }
]