import {BaseRequest} from "@/request/BaseRequest";

class Common extends BaseRequest {

    /**
     * 获取七牛token
     */
    getQiNiuToken(): any {
        return this.request({
            url: '/tool/qiniu_key',
            method: 'get'
        })
    }

    /**
     * 获取商户对应版本组件
     */
    getComponents(): any {
        return this.request({
            url: '/tool/componentList',
            method: 'get'
        })
    }


}

const c = new Common();
export {c as Common}