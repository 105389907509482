
const module: string = 'after_sale';

export default [
    {
        name: `${module}_orders`,
        component: import('@/views/after-sale/Order.vue')
    },
    {
        name: `${module}_coupons`,
        component: import('@/views/after-sale/Coupon.vue')
    },
    {
        name: `${module}_delivery`,
        component: import('@/views/after-sale/Delivery.vue')
    },
    {
        name: `${module}_storedRecords`,
        component: import('@/views/after-sale/StoredRecords.vue')
    },
    {
        name: `${module}_withdrawCash`,
        component: import('@/views/after-sale/WithdrawCash.vue')
    },
    {
        name: `${module}_distributionOrders`,
        component: import('@/views/after-sale/DistributionOrders.vue')
    },
    {
        name: `${module}_withdrawApply`,
        component: import('@/views/after-sale/WithdrawApply.vue')
    },
    {
        name: `${module}_appointment`,
        component: import('@/views/after-sale/Appointment.vue')
    }
]