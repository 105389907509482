import axios from 'axios'
// import qs from 'qs'
import config from "@/config";
import router from "@/router";
import store from '@/store'
import {Message, Modal} from 'view-design';

/**
 * axios 基本配置
 * @type {number}
 */
const service = axios.create({
    timeout : 60000,
    baseURL : config.baseUrl,
    method: 'post'
    /*headers : {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    },*/
    // transformRequest : data => qs.stringify(data)
});

service.interceptors.request.use(
    (config: any) => {
        let userInfo: any = sessionStorage.getItem(store.getters.info);
        if (userInfo) {
            userInfo = JSON.parse(userInfo);
            config.headers['token'] = userInfo.token
        }
        return config
    },
    error => Promise.reject(error)
);

service.interceptors.response.use(
    response => {
        if (response['status'] === 200) {
            let data: any = response.data;
            if (data['code'] && data['code'] !== 0) {
                console.log('\n请求地址：', response.request.responseURL);
                // @ts-ignore
                Message.warning(data['msg']);
                if (data['code'] === 999) {
                    setTimeout(() => {
                        router.replace('/login')
                    }, 2000);
                }
            }
        }
        return response.data
    },
    error => {
        if (!error.response || error.response.status === 401) {
            // @ts-ignore
            Modal.warning({
                title: '提示',
                content: '您的账号已在别处登陆',
                onOk() {
                    router.replace('/login');
                }
            });
        }
        return Promise.reject(error)
    }
);

export default service
