
const module: string = 'customer';

export default [
    {
        name: `${module}_user`,
        component: import('@/views/customer/User.vue')
    },
    {
        name: `${module}_memberSet`,
        component: import('@/views/customer/MemberSet.vue')
    },
    {
        name: `${module}_preCustomer`,
        component: import('@/views/customer/PreCustomer.vue')
    },
    {
        name: `${module}_userStored`,
        component: import('@/views/customer/UserStored.vue')
    },
    {
        name: `${module}_distributionUser`,
        component: import('@/views/customer/DistributionUser.vue')
    }
]