import request from '@/axiosConfig'

export class BaseRequest {

    protected requestPath!: string;

    /**
     * 新增/保存
     */
    private SAVE_OR_UPDATE_URL: string = 'saveOrUpdate';

    /**
     * 删除
     */
    private DEL_URL: string = 'del';

    /**
     * 查找
     */
    private FIND_ID_URL: string = 'findById';

    /**
     * 列表
     */
    private LIST_URL: string = 'list';

    /**
     * 所有
     */
    private GET_All_URL: string = 'getAll';

    /**
     * 开关
     */
    public ON_OFF_URL: string = 'onOff';

    save(data: any): any {
        return request({
            url: `${this.requestPath}/${this.SAVE_OR_UPDATE_URL}`,
            method: 'post',
            data
        })
    }

    saveWithUrl(url: string, data: any): any {
        return request({
            url: url,
            data
        })
    }

    del(ids: Array<string>): any {
        return request({
            url: `${this.requestPath}/${this.DEL_URL}`,
            method: 'delete',
            data: {id: ids}
        })
    }

    delWithUrl(url: string, ids: Array<string>): any {
        return request({
            url: url,
            method: 'delete',
            data: {id: ids}
        })
    }

    findById(id: string): any {
        return request({
            url: `${this.requestPath}/${this.FIND_ID_URL}/${id}`,
            method: 'get'
        })
    }

    findByIdWithUrl(url: string, id: string): any {
        return request({
            url: `${url}/${id}`,
            method: 'get'
        })
    }

    list(data: any): any {
        return request({
            url: `${this.requestPath}/${this.LIST_URL}`,
            method: 'post',
            data
        })
    }

    /**
     * 获取所有
     */
    getAll(params?: string): any {
        return request({
            url: `${this.requestPath}/${this.GET_All_URL}/${params||''}`,
            method: 'get'
        })
    }

    listWithUrl(url: string, data: any = {}): any {
        return request({
            url: url,
            method: 'post',
            data
        })
    }

    /**
     * 开关接口
     * @param data
     */
    onOff(data: {id: string, status: number}) {
        return request({
            url: `${this.requestPath}/${this.ON_OFF_URL}`,
            method: 'post',
            data
        })
    }

    /**
     *
     * @param url
     * @param data
     */
    onOffWithUrl(url: string, data: {id: string, status: number}) {
        return request({
            url: url,
            method: 'post',
            data
        })
    }

    exportWithUrl(url: string, data: any = {}): any {
        return request({
            url: url,
            method: 'post',
            data,
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    get(url: string): any {
        return request({
            url: url,
            method: 'get'
        })
    }

    post(url: string, data: any = {}): any {
        return request({
            url: url,
            data
        })
    }

    request(params: any): any {
        return request(params)
    }
}
