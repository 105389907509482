import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ViewUI from 'view-design';
import Print from 'vue-print-nb';
import VueClipboard from 'vue-clipboard2';
import components from '@/components/components'
import directives from '@/components/directives'
import '@/common/DateFormat'
import config from '@/config'
import ModuleType from "@/common/ModuleType";

import 'view-design/dist/styles/iview.css';

Vue.config.productionTip = false;

// Vue.use(ViewUI)
Vue.use(ViewUI, {
    transfer: true,
    capture: false,
    select: {
        arrow: 'md-arrow-dropdown',
        arrowSize: 20
    }
});

Vue.use(Print);
VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard);

Vue.prototype.$Message.config({
    duration: 2
});
Vue.prototype.$Notice.config({
    duration: 3
});
Vue.prototype.assignObject = (o: any) => {
    return JSON.parse(JSON.stringify(o));
};
Vue.prototype.fen2yuan = (fen: any) => {
    return (fen / 100).toFixed(2);
};
Vue.prototype.yuan2fen = (yuan: any) => {
    return yuan * 100;
};

Vue.use(components);
Vue.use(directives);

Vue.prototype.website = config.website;
Vue.prototype.$cdn = config.cdn;
Vue.prototype.$cdns = config.cdns;
Vue.prototype.$moduleType = ModuleType;

Vue.prototype.$drawerWidth = 720; //抽屉宽度
Vue.prototype.$styles = {
    height: 'calc(100% - 55px)',
    overflow: 'auto',
    paddingBottom: '53px',
    position: 'static'
};

new Vue({
    router,
    store,
    render: (h: any) => h(App)
}).$mount('#app');
