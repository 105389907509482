/**
 * 商品管理
 */

const module: string = 'shop';

export default [
    {
        name: `${module}_first_page`,
        component: import('@/views/shop/FirstPage.vue')
    }
]