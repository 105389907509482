<template>
    <div class="container">
        <transition>
            <router-view/>
        </transition>
    </div>
</template>

<script>
    export default {
        data() {
            return {}
        }
    }
</script>

<style lang="less">
    .spin-icon-load {
        animation: ani-demo-spin 1s linear infinite;
    }
</style>
