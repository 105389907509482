import Vue from 'vue'
import Vuex from 'vuex'
import {Common} from "@/request/Common";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        info: 'userInfo',
        ops: 'ops',
        comp: {
            comp: ''
        },
        upToken: {pushTime: Date, timeout: Number, token: String}
    },
    getters: {
        info: state => state.info,
        ops: state => state.ops,
        comp: state => state.comp,
        upToken: state => state.upToken,
    },
    mutations: {
        currentComp: (state, payload) => {state.comp=payload},
        upToken: (state, payload) => {state.upToken=payload}
    },
    actions: {
        qiniuToken(context) {
            return new Promise((resolve, reject) => {
                let now = new Date();
                let upToken: any = context.state.upToken;
                if (upToken && ((now.getTime() - upToken.pushTime) < upToken.timeout)) {
                    console.warn('获取缓存token: ', upToken.token);
                    resolve(upToken.token);
                } else {
                    Common.getQiNiuToken().then((body: any) => {
                        if (body['uptoken']) {
                            let token = body['uptoken'];
                            console.warn('获取七牛云token:',token);
                            context.commit('upToken', {
                                pushTime: new Date().getTime(),
                                timeout: 1000 * 60,
                                token: body['uptoken']
                            });
                            resolve(token)
                        } else {
                            console.error('七牛云token获取失败');
                            reject()
                        }
                    })
                }
            })
        },
        getOperates(context) {
            return new Promise(resolve => {
                let ops: Array<object> = [];
                let sessionOpsStr = sessionStorage.getItem(context.state.ops);
                if (sessionOpsStr) {
                    let sessionOps: Array<any> = JSON.parse(sessionOpsStr);
                    sessionOps.forEach((j: any) => {
                        if (j.comp === context.state.comp.comp) ops = j.ops.filter((j: any) => j.key !== '')
                    });
                }
                console.log('权限：', ops);
                let pubOps = ops.filter((j: any) => (j.key==='add') || (j.key==='import') || (j.key==='export'));
                let tableOps = ops.filter((j: any) => pubOps.indexOf(j) < 0);
                resolve({
                    pubOps: pubOps,
                    tableOps: tableOps
                })
            })
        }
    }
});

export default store;
