
import Goods from "@/asyncComponents/Goods";
import Merchant from "@/asyncComponents/Merchant";
import Shop from "@/asyncComponents/Shop";
import AfterSale from "@/asyncComponents/AfterSale";
import FirstPageComponents from "@/asyncComponents/FirstPageComponents";
import Marketing from "@/asyncComponents/Marketing";
import Customer from "@/asyncComponents/Customer";
import Store from "@/asyncComponents/Store";
import DataCenter from "@/asyncComponents/DataCenter";

const children = [
    ...Goods,
    ...Merchant,
    ...Shop,
    ...AfterSale,
    ...FirstPageComponents,
    ...Marketing,
    ...Customer,
    ...Store,
    ...DataCenter
];

export default (Vue: any) => {
    Vue.component(
        'home',
        () => import('@/views/Home.vue')
    );
    Vue.component(
        'el-table',
        // 这个 `import` 函数会返回一个 `Promise` 对象。
        () => import('./el-table.vue')
    );
    Vue.component(
        'el-map',
        () => import('./el-map.vue')
    );
    Vue.component(
        'image-preview',
        () => import('./image-preview.vue')
    );
    Vue.component(
        'upload-image',
        () => import('./upload-image.vue')
    );
    Vue.component(
        'el-search',
        () => import('./el-search.vue')
    );
    /*Vue.component(
        'el-line',
        () => import('./el-line.vue')
    );
    Vue.component(
        'el-filter-columns',
        () => import('./el-filter-columns.vue')
    );*/
    Vue.component(
        'el-editor',
        () => import('./el-editor.vue')
    );
    Vue.component(
        'el-chart',
        () => import('./el-chart.vue')
    );
    Vue.component(
        'el-pub-ops',
        () => import('./el-pub-ops.vue')
    );
    Vue.component(
        'el-file-upload',
        () => import('./upload-video.vue')
    );
    Vue.component(
        'video-preview',
        () => import('./video-preview.vue')
    );
    Vue.component(
        'el-permission',
        () => import('./el-permission.vue')
    );

    /**
     * 注册页面组件/首页动态组件
     */
    children.forEach((j: {name: string, component: any}) => {
        Vue.component(j.name, () => j.component);
    });

    Vue.component('first-page-component', {
        /*render (createElement: any) {
            return createElement(
                'h' + this.level,   // 标签名称
                this.$slots.default // 子节点数组
            )
        },*/
        render(h: any) {
            let self: any = this;
            return h(self.component, {
                props: {
                    id: self.id
                }
            })
        },
        props: {
            id: {
                type: String,
                required: true
            },
            component: {
                type: String,
                required: true
            }
        }
    });
}
