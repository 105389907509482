/**
 * 数据中心
 */

const module: string = 'data';

export default [
    {
        name: `${module}_transaction`,
        component: import('@/views/data-center/Transaction.vue')
    },
    {
        name: `${module}_employeeNewUser`,
        component: import('@/views/data-center/EmployeeNewUser.vue')
    },
    {
        name: `${module}_userStat`,
        component: import('@/views/data-center/UserStat.vue')
    },
    {
        name: `${module}_popularize`,
        component: import('@/views/data-center/Popularize.vue')
    },
    {
        name: `${module}_categorySale`,
        component: import('@/views/data-center/CategorySale.vue')
    },
    {
        name: `${module}_distributionStatistic`,
        component: import('@/views/data-center/DistributionStatistic.vue')
    }
]