
// let env = 'dev'; //测试环境
let env = 'prod'; //正式环境

let baseUrl: string = '';
let cdn: string = '';
let cdns: string = '';

switch (env) {
    case 'dev':
        // baseUrl = 'http://test.data.merchant.xgf365.com';
        baseUrl = 'http://data.merchant.xgf365.com';
        cdn = 'http://files.xgf365.com/';
        cdns = 'http://files.xgf365.com/';
        break;
    case 'prod':
        baseUrl = 'https://data.merchant.xgf365.com';
        cdn = 'http://files.xgf365.com/';
        cdns = 'https://files.xgf365.com/';
        break;
}

const website = '';

export default {
    website,
    baseUrl,
    cdn,
    cdns
}