/**
 * 积分商城
 */

const module: string = 'marketing';

export default [
    {
        name: `${module}_integralGoods`,
        component: import('@/views/marketing/IntegralGoods.vue')
    },
    {
        name: `${module}_integralExchangeSet`,
        component: import('@/views/marketing/IntegralExchangeSet.vue')
    },
    {
        name: `${module}_couponsSet`,
        component: import('@/views/marketing/CouponsSet.vue')
    },
    {
        name: `${module}_couponsInfo`,
        component: import('@/views/marketing/CouponsInfo.vue')
    },
    {
        name: `${module}_signInRecords`,
        component: import('@/views/marketing/SignInRecords.vue')
    },
    {
        name: `${module}_integralSignInSet`,
        component: import('@/views/marketing/IntegralSignInSet.vue')
    },
    {
        name: `${module}_activityInfo`,
        component: import('@/views/marketing/ActivityInfo.vue')
    },
    {
        name: `${module}_advertise`,
        component: import('@/views/marketing/Advertise.vue')
    },
    {
        name: `${module}_distributionSet`,
        component: import('@/views/marketing/DistributionSet.vue')
    },
    {
        name: `${module}_storedControl`,
        component: import('@/views/marketing/StoredControl.vue')
    }
]